export default {
    mounted(el,binding) {
        el.addEventListener('keydown', (event)=>{
            if(event && event.key === 'Enter') {
                if (binding && typeof binding.value === 'function') {
                    binding.value(); // Call the passed function
                  }
            }
        })
    },
    unmounted(el) {
        el.removeEventListener('keydown', null);
    }
  };