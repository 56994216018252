<template>
  <div class="w-full flex flex-col flex-grow items-center space-y-1">
    <div class="w-full flex flex-row items-center justify-between truncate flex-wrap px-2 panel">
        <div class="text-left p-1 whitespace-normal truncate font-semibold">Project Total</div>
        <div class="w-auto flex flex-row items-center flex-wrap flex-grow justify-end truncate max-w-sm">
            <div class="px-3 whitespace-nowrap">{{`${servicesSummary.totalHours} ${servicesSummary.totalHours && servicesSummary.totalHours === 1 ? ' hour' : ' hours'}`}}</div>
            <div class="whitespace-nowrap truncate">{{showAsUSCurrency(servicesSummary.totalRevenue)}}</div>
        </div>
    </div>
    <div class="w-full flex flex-col flex-grow flex-auto h-0 overflow-y-auto p-1">
        <div class="w-full flex flex-col flex-grow items-center overflow-y-auto">
            <div v-for="(service,index) in contractSummary" :key="index" class="w-full">
                <div class="w-full flex items-center flex-nowrap truncate">
                    <div class="w-full flex items-center py-1 border-b truncate flex-wrap">
                        <div class="w-44 min-w-44 text-left whitespace-normal truncate p-1">{{service.partNumber}}</div>
                        <div class="flex-grow flex flex-row items-center truncate flex-wrap">
                            <div class="flex-grow flex items-center">
                                <div class="w-full text-left whitespace-normal truncate p-1">
                                    {{service.description}}
                                </div>
                                <!--<input v-model.trim="service.description" type="text" class="w-full max-w-md min-w-60" maxlength="100">-->
                            </div>
                            <div class="w-auto flex items-center flex-wrap p-1">
                                <div class="py-1 flex flex-row space-x-2 items-center pr-2">
                                    <div class="px-1">{{`${service.hours} ${service.hours > 1 ? 'hrs' : ''}`}}</div>
                                    <!--<input v-model="service.hours" v-hoursInput type="number" class="w-20 text-center truncate" max="10000" step=".25">-->
                                    <!--<div>hrs.</div>-->
                                </div>
                                <div class="w-auto py-1 flex flex-row space-x-2 items-center truncate pl-2">
                                    <div class="px-1 text-right w-40 truncate">{{`${showAsUSCurrency(service.revenue)}`}}</div>
                                    <!--<div>$</div>
                                    <input v-model="service.revenue" v-currency type="text" class="text-right w-32 truncate" max="1000000000">-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- Contract -->
            <!--<div v-if="contractSummary && Object.keys(contractSummary).length > 0" class="w-full flex flex-col">
                <div v-for="(object,document) in contractSummary" :key="document" class="w-full flex flex-col p-1">
                    <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1 bg-indigo-300 dark:bg-indigo-600 sticky top-0">
                        <div class="text-left p-1 whitespace-normal truncate font-semibold">{{document}}</div>
                        <div class="w-auto flex flex-row items-center flex-wrap flex-grow justify-end truncate max-w-sm">
                            <div class="px-2 whitespace-nowrap">{{`${object.summary?.hours} ${object.summary?.hours && object.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                            <div class="whitespace-nowrap truncate">{{showAsUSCurrency(object.summary?.revenue)}}</div>
                        </div>
                    </div>
                    <div v-if="object.categories && Object.keys(object.categories).length > 0" class="w-full p-1">
                        <div v-for="(categoryObject,category) in object.categories" :key="category" class="w-full p-1">
                            <div class="w-full flex flex-row items-center justify-between border rounded-md px-1 flex-wrap has-details font-semibold" @click="openDetails($event?.target)">
                                <div class="text-left p-1 whitespace-normal truncate">{{category}}</div>
                                <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                    <div class="px-2 whitespace-nowrap">{{`${categoryObject.summary?.hours} ${categoryObject.summary?.hours && categoryObject.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                    <div class="whitespace-nowrap truncate">{{showAsUSCurrency(categoryObject.summary?.revenue)}}</div>
                                </div>
                            </div>
                            <div v-if="categoryObject.services && Object.keys(categoryObject.services).length > 0" class="w-full p-1 hidden">
                                <div v-for="(servicesObject,partNumber) in categoryObject.services" :key="partNumber" class="w-full">
                                    <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1 bg-indigo-700 bg-opacity-20">
                                        <div class="text-left p-1 whitespace-normal truncate">{{partNumber}}</div>
                                        <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                            <div class="px-2 whitespace-nowrap">{{`${servicesObject.summary?.hours} ${servicesObject.summary?.hours && servicesObject.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                            <div class="whitespace-nowrap truncate">{{showAsUSCurrency(servicesObject.summary?.revenue)}}</div>
                                        </div>
                                    </div>
                                    <div v-if="Array.isArray(servicesObject.items) && servicesObject.items.length > 0" class="w-full">
                                        <div v-for="(item,index) in servicesObject.items" :key="index" class="w-full py-1">
                                            <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1">
                                                <div class="text-left p-1 whitespace-normal truncate">{{item.description}}</div>
                                                <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                                    <div class="px-2 whitespace-nowrap">{{`${item.hours} ${item.hours && item.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                                    <div class="whitespace-nowrap truncate">{{showAsUSCurrency(item.revenue)}}</div>
                                                    <div v-if="allowEdit" class="flex items-center px-2 pb-0.5">
                                                        <ButtonWithConfirm :popperContent="'delete item'" :iconComponent="TrashIcon" @confirmed="deleteServiceItem(item._id)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
        <!-- Change Orders -->
            <!--<div v-if="changeSummary && Object.keys(changeSummary).length > 0" class="w-full text-left whitespace-normal">
                <div v-for="(object,document) in changeSummary" :key="document" class="w-full flex flex-col p-1">
                    <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1 bg-indigo-300 dark:bg-indigo-600 sticky top-0">
                        <div class="text-left p-1 whitespace-normal truncate font-semibold">{{document}}</div>
                        <div class="w-auto flex flex-row items-center flex-wrap flex-grow justify-end truncate max-w-sm">
                            <div class="px-2 whitespace-nowrap">{{`${object.summary?.hours} ${object.summary?.hours && object.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                            <div class="whitespace-nowrap truncate">{{showAsUSCurrency(object.summary?.revenue)}}</div>
                        </div>
                    </div>
                    <div v-if="object.categories && Object.keys(object.categories).length > 0" class="w-full p-1">
                        <div v-for="(categoryObject,category) in object.categories" :key="category" class="w-full p-1">
                            <div class="w-full flex flex-row items-center justify-between border rounded-md px-1 flex-wrap has-details font-semibold" @click="openDetails($event?.target)">
                                <div class="text-left p-1 whitespace-normal truncate">{{category}}</div>
                                <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                    <div class="px-2 whitespace-nowrap">{{`${categoryObject.summary?.hours} ${categoryObject.summary?.hours && categoryObject.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                    <div class="whitespace-nowrap truncate">{{showAsUSCurrency(categoryObject.summary?.revenue)}}</div>
                                </div>
                            </div>
                            <div v-if="categoryObject.services && Object.keys(categoryObject.services).length > 0" class="w-full p-1 hidden">
                                <div v-for="(servicesObject,partNumber) in categoryObject.services" :key="partNumber" class="w-full">
                                    <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1 bg-indigo-700 bg-opacity-20">
                                        <div class="text-left p-1 whitespace-normal truncate">{{partNumber}}</div>
                                        <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                            <div class="px-2 whitespace-nowrap">{{`${servicesObject.summary?.hours} ${servicesObject.summary?.hours && servicesObject.summary?.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                            <div class="whitespace-nowrap truncate">{{showAsUSCurrency(servicesObject.summary?.revenue)}}</div>
                                        </div>
                                    </div>
                                    <div v-if="Array.isArray(servicesObject.items) && servicesObject.items.length > 0" class="w-full">
                                        <div v-for="(item,index) in servicesObject.items" :key="index" class="w-full py-1">
                                            <div class="w-full flex flex-row items-center justify-between border-b truncate flex-wrap px-1">
                                                <div class="text-left p-1 whitespace-normal truncate">{{item.description}}</div>
                                                <div class="w-auto flex flex-row items-center flex-nowrap flex-grow justify-end truncate max-w-sm">
                                                    <div class="px-2 whitespace-nowrap">{{`${item.hours} ${item.hours && item.hours === 1 ? ' hour' : ' hours'}`}}</div>
                                                    <div class="whitespace-nowrap truncate">{{showAsUSCurrency(item.revenue)}}</div>
                                                    <div v-if="allowEdit" class="flex items-center px-2 pb-0.5">
                                                        <ButtonWithConfirm :popperContent="'delete item'" :iconComponent="TrashIcon" @confirmed="deleteServiceItem(item)" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
  </div>
</template>

<script>
import {showAsUSCurrency,openDetails,naturalSort} from '@/shared'
import { computed } from 'vue'
//import ButtonWithConfirm from '@/components/ui/ButtonWithConfirm.vue'
import { TrashIcon } from '@heroicons/vue/outline'
export default {
    props:{
        services:{type:Array,default:()=>{return []}},
        allowEdit:{type:Boolean,default:false}
    },
    components:{},
    emits:['deleteItem'],
    setup(props,{emit}) {

        const contractSummary = computed(()=>{
            return naturalSort(props.services,'partNumber','description')
            //return groupServices(props.services)
        })

        //const changeSummary = computed(()=>{
        //    return naturalSort(props.services,'partNumber','description')
        //    //return groupServices(props.services,false)
        //})

        const servicesSummary = computed(()=>{
            let totalHours = 0
            let totalRevenue = 0

            for (let item of props.services) {
                if(typeof item.hours === 'number') {
                    totalHours += item.hours > 1 ? item.hours : 0
                }
                if (typeof item.revenue === 'number') {
                    totalRevenue += item.revenue;
                }
            }

            return {
                totalHours,
                totalRevenue
            }

        })

        const deleteServiceItem = (id)=>{
            emit("deleteItem",id)
        }

        return {
            contractSummary,
            //changeSummary,
            showAsUSCurrency,
            openDetails,
            servicesSummary,
            TrashIcon,
            deleteServiceItem 
        }
    }
}
</script>

<style>

</style>